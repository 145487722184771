const getBrightness = (color: string) => {
  const hex = color.replace('#', '');
  const c_r = parseInt(hex.substring(0, 0 + 2), 16);
  const c_g = parseInt(hex.substring(2, 2 + 2), 16);
  const c_b = parseInt(hex.substring(4, 4 + 2), 16);
  return ((c_r * 299) + (c_g * 587) + (c_b * 114)) / 1000;
};

export const isLight = (color: string) => {
  const brightness= getBrightness(color);
  return brightness > 155;
};

/**
 * Used to show border around very white color swatch
 * @param color
 */
export const isTooLight = (color: string) => {
  const brightness= getBrightness(color);
  return brightness > 245;
};
